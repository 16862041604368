<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <!-- Filters -->
    <b-row
      class="px-2"
    >
      <b-col
        cols="12"
        md="6"
        class="px-1"
        order="3"
        order-md="1"
      >
        <b-form-group
          label="Search"
          label-for="filter_history_search"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_history_search"
            v-model="tableCreditCards.filter.search"
            type="text"
            size="sm"
            placeholder="search here"
            debounce="1000"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        md="3"
        class="px-1"
        order="2"
      >
        <b-form-group
          label="Released From"
          label-for="filter_released_from"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_released_from"
            v-model="tableCreditCards.filter.date_from"
            size="sm"
            type="datetime-local"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        md="3"
        class="px-1"
        order="2"
      >
        <b-form-group
          label="Released To"
          label-for="filter_released_to"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_released_to"
            v-model="tableCreditCards.filter.date_to"
            size="sm"
            type="datetime-local"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="isValidForExport"
        class="px-1 mb-2"
        order="3"
      >
        <b-overlay
          rounded
          opacity="0.1"
          spinner-small
          class="d-inline"
          spinner-variant="warning"
          :show="exporter.excel.exporting"
        >
          <json-excel
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="btn btn-success btn-sm"
            :fetch="excelProvider"
            :before-generate="onStartExcelExport"
            :before-finish="onFinishExcelExport"
            :fields="exporter.excel.fields"
            :escape-csv="exporter.excel.escape"
            :type="exporter.excel.type"
            :header="exporter.excel.header"
            :footer="exporter.excel.footer"
            :name="excelFileName"
            worksheet="Replenishment of Credit Card"
          >
            {{ exporter.excel.exporting ? 'Exporting' : 'Export As Excel' }}
          </json-excel>
        </b-overlay>
      </b-col>

    </b-row>
    <!-- Table -->
    <b-row
      class="mt-1 px-2"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tableCreditCards"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          :stacked="isMobile()"
          :per-page="tableCreditCards.perPage"
          :current-page="tableCreditCards.currentPage"
          :items="tableCreditCardsProvider"
          :fields="tableCreditCards.fields"
          :sort-by.sync="tableCreditCards.sortBy"
          :sort-desc.sync="tableCreditCards.sortDesc"
          :sort-direction="tableCreditCards.sortDirection"
          :filter="tableCreditCards.filter"
          :filter-included-fields="tableCreditCards.filterOn"
          :busy="tableCreditCards.busy"
        >
          <template #cell(index)="row">
            {{ tableCreditCards.currentPage * tableCreditCards.perPage - tableCreditCards.perPage + (row.index + 1) }}
          </template>

          <template #cell()="row">
            <div class="text-md-nowrap">
              {{ row.value }}
            </div>
          </template>

          <template #cell(approvable.voucher.voucher_number)="row">
            <div class="text-md-nowrap">
              <b>{{ row.value }}</b>
            </div>
          </template>

          <template #cell(approvable.amount)="row">
            <div
              class="text-md-nowrap text-md-right"
              style="min-width: 100px;"
            >
              {{ row.value }}
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tableCreditCards.perPage"
          size="sm"
          class="w-100 w-md-25"
          :options="tableCreditCards.pageOptions"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tableCreditCards.currentPage"
          pills
          last-number
          first-number
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
          :total-rows="tableCreditCards.totalRows"
          :per-page="tableCreditCards.perPage"
        />
      </b-col>

    </b-row>

    <b-modal
      id="modal-export-credit-card"
      size="xl"
      scrollable
      hide-header
      no-enforce-focus
      no-close-on-backdrop
      modal-class="fullscreen"
    >
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
      >
        <b-tab
          title="Credit Cards"
          class="attachment-container-larger"
        >
          <b-overlay
            rounded
            opacity="1"
            :show="exporter.pdf.data === null"
            spinner-variant="success"
          >
            <iframe
              :src="exporter.pdf.data"
            />
          </b-overlay>
        </b-tab>
      </b-tabs>
      <template #modal-footer="{ cancel }">
        <div class="col-12 d-flex justify-content-between px-0 pb-0 mb-0">
          <div />
          <div>
            <b-button
              variant="danger"
              @click="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { DAReportsCreditCardsService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {

  name: 'DaReportsCreditCards',

  middleware: ['auth', 'da'],

  directives: {
    Ripple
  },

  mixins: [formatter, misc],

  data () {
    return {
      busy: false,
      exporter: {
        busy: false,
        pdf: {
          exporting: false,
          data: null
        },
        excel: {
          exporting: false,
          header: [],
          escape: false,
          type: 'xls',
          fields: {
            Date: {
              field: 'approvable.released_at',
              callback: date => this.dateShortFormatter(date)
            },
            Particulars: {
              field: 'approvable.particulars'
            },
            Amount: {
              field: 'approvable.amount',
              callback: amount => this.numberFormatter(amount)
            }
          },
          footer: []
        }
      },
      tableCreditCards: {
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null,
          date_from: moment().format('YYYY-MM-DDT08:00'),
          date_to: moment().format('YYYY-MM-DDT17:00')
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'approvable.released_at', label: 'Released At', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'approvable.voucher.voucher_number', label: 'Voucher Number', class: 'text-center' },
          { mobile: 3, key: 'approvable.bp_name', label: 'Business Partner' },
          { mobile: 4, key: 'approvable.customer_name', label: 'Cash Advance' },
          { mobile: 5, key: 'approvable.mop', label: 'Mode of Payment' },
          { mobile: 6, key: 'approvable.amount', label: 'Amount', formatter: this.numberFormatter },
          { mobile: 7, key: 'approvable.requestor', label: 'Requested By', formatter: this.userCurrentRequestor }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    excelFileName () {
      const dateFrom = this.dateTimeShortFormatter(this.tableCreditCards.filter.date_from)
      const dateTo = this.dateTimeShortFormatter(this.tableCreditCards.filter.date_to)
      return `Replenishment for Credit Cards ( ${dateFrom} to ${dateTo} )`
    },

    isValidForExport () {
      return (
        this.tableCreditCards.filter.date_from !== null && this.tableCreditCards.filter.date_to !== null
      )
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableCreditCardsProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableCreditCards.busy = true
      return await DAReportsCreditCardsService.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          filter_time_from: filter.date_from,
          filter_time_to: filter.date_to
        })
      ).then(({ data }) => {
        this.tableCreditCards.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableCreditCards.busy = false
      })
    },

    async excelProvider () {
      if (this.exporter.busy) {
        return null
      }
      this.exporter.busy = this.exporter.excel.exporting = true
      return await DAReportsCreditCardsService.export(
        this.objectToUrl({
          filter_text: this.tableCreditCards.filter.search,
          filter_time_from: this.tableCreditCards.filter.date_from,
          filter_time_to: this.tableCreditCards.filter.date_to
        })
      ).then(({ data }) => {
        if (data.items.length <= 0) {
          this.swalInvalid(
            'No available data to be Exported'
          )
        }
        this.setExcelFooter(data.items)
        return data.items
      }).catch(() => []).finally(() => {
        this.exporter.busy = this.exporter.excel.exporting = false
      })
    },

    onStartExcelExport () {
      if (this.exporter.busy) {
        return null
      }
      const dateFrom = this.dateTimeLongFormatter(this.tableCreditCards.filter.date_from)
      const dateTo = this.dateTimeLongFormatter(this.tableCreditCards.filter.date_to)
      this.exporter.excel.header = [
        'PAYMENT OF CREDIT CARD FTM',
        'ALJAY AGRO-INDUSTRIAL SOLUTIONS, INCORPORATED',
        `${dateFrom} - ${dateTo}`,
        '&nbsp;'
      ]
    },

    setExcelFooter (creditCards = []) {
      if (creditCards && creditCards.length > 0) {
        this.exporter.excel.footer = [
          `<table>
            <thead>
              <tr>
                <th colspan="3" style="text-align: left;">
                  &nbsp;
                </th>
              </tr>
              <tr>
                <th colspan="2" style="text-align: left;">
                  TOTAL
                </th>
                <th style="text-align: right;">
                  ${
                    this.numberFormatter(
                      creditCards.map(
                        ({ approvable }) => approvable.amount
                      ).reduce(
                        (total, amount) => Number(total) + Number(amount), 0
                      )
                    )
                  }
                </th>
              </tr>
            </thead>
          </table>`
        ].concat([
          `<table>
            <tbody>
              <tr>
                <td colspan="3" style="text-align: left;">
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  Prepared By:
                </td>
                <td colspan="1">
                  Checked By:
                </td>
                <td colspan="1">
                  Approved By:
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  ${this.$store.getters['auth/user'].name}
                </td>
                <td colspan="1">
                  Joni A. Yusongco
                </td>
                <td colspan="1">
                  Nikko R. Bayang, CPA
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  Disbursement Associate
                </td>
                <td colspan="1">
                  Disbursement Head
                </td>
                <td colspan="1">
                  Financial Operation Manager
                </td>
              </tr>
            </tbody>
          </table>`
        ])
      }
    },

    onFinishExcelExport () {
    },

    tableCreditCardsRefresh () {
      this.$refs.tableCreditCards.refresh()
    }
  }

}
</script>
